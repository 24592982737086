import { render, staticRenderFns } from "./Weapons.vue?vue&type=template&id=02a8494b&"
import script from "./Weapons.vue?vue&type=script&lang=js&"
export * from "./Weapons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports